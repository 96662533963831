<template>
<div :class="`${size}`">
    <v-card>
            <template v-if="size === 'xs'">
                <v-card-title>
                    Prestashop
                </v-card-title>
                <v-container>
                    <v-list-item two-line>
                        <v-list-item-content class="py-0" style="align-self:start">
                            <v-list-item-subtitle>Nombre de commandes du mois en cours :</v-list-item-subtitle>
                            <v-list-item-title class="headline font-weight-bold" style="text-align:center;font-size:xxx-large !important">
                                327
                                <v-list-item-subtitle class="pt-0" style="color:green">+8%</v-list-item-subtitle>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </template>
            <template v-else-if="size === 'sm'">
                <v-card-title>
                    Prestashop
                </v-card-title>
                <v-card-text class="py-0">Statut des commandes du mois en cours :
                    <canvas id="myChart" height="300" class="text--secondary"></canvas>
                </v-card-text>
            </template>
    </v-card>
    </div>
</template>

<script>
import Chart from 'chart.js';

export default {
    name : 'Prestashop',
    props : {
        size : {
            type : String,
            default : 'xs'
        }
    },
    methods : {
        createChart() {
            var ctx = document.getElementById('myChart');
            new Chart(ctx, {
                type: 'doughnut',
                data:  {
                    datasets: [{
                        data: [10, 200, 117],
                        backgroundColor: ['#ff6384', '#ffcd56', '#36a2eb'],
                        borderColor : 'transparent'
                    }],
                    // These labels appear in the legend and in the tooltips when hovering different arcs
                    labels: [
                        'Dans le panier',
                        'Expédiée',
                        'En validation'
                    ]
                },
                options: {
                    legend: {
                        labels: {
                            // This more specific font property overrides the global property
                            fontColor: this.$vuetify.theme.dark ? '#acacac' : '#acacac',
                        }
                    }
                }
            });
        }
    },
    mounted() {
        this.createChart();
    },
    updated() {
        this.createChart();
    }
}
</script>

<style lang="scss" scoped>
.v-card {
    overflow: hidden;
}

.widget {
    @media screen and (max-width: 600px) {
        grid-column: span 2;
        grid-row: span 4;   
    }
    @media screen and (min-width: 600px) {
        grid-column: span 2;
        grid-row: span 2;
    }
    @media screen and (min-width: 1200px) {
        &.xs {
            grid-column: span 2;
            grid-row: span 2;
        }
        &.sm {
            grid-column: span 2;
            grid-row: span 4;
        }
        &.md {
            grid-column: span 2;
            grid-row: span 6;
        }
        &.lg {
            grid-column: span 4;
            grid-row: span 4;
        }
        &.xl {
            grid-column: span 4;
            grid-row: span 6;
        }
        &.xmd {
            grid-column: span 8;
            grid-row: span 6;
        }
    }
}
</style>