import { render, staticRenderFns } from "./Prestashop.vue?vue&type=template&id=24b689b0&scoped=true&"
import script from "./Prestashop.vue?vue&type=script&lang=js&"
export * from "./Prestashop.vue?vue&type=script&lang=js&"
import style0 from "./Prestashop.vue?vue&type=style&index=0&id=24b689b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b689b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VCardText,VCardTitle,VContainer,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
